<template>
    <v-container>
        <div class="text-center my-md-10">
            <p class="text-h5">Choose the best option for you</p>
        </div>
        <v-row justify="center">
            <v-col sm="6" md="4" lg="4">
                <v-card elevation="2">
                    <div class="title-option text-center">
                        Basic
                    </div>
                    <div class="price-option text-center">
                        <p class="pt-5"><strong>$ 0</strong> /year</p>
                    </div>
                    <div class="text-center">
                        <v-btn class="ma-2 subtitle-1" depressed color="light-blue accent-4" dark large :to="{name: 'SignUp', params: {type: 'free'}}">
                            Sign Up Free
                        </v-btn>
                    </div>
                    <v-list class="ml-sm-4 ml-md-9 ml-lg-14 ml-xl-16">
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Free</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Resources</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    
                </v-card>
            </v-col>
            <v-col sm="6" md="4" lg="4">
                <v-card elevation="2">
                    <div class="title-option text-center">
                        Full
                    </div>
                    <div class="price-option text-center">
                        <del>
                            <span class="amount">$ 75,00</span>
                        </del>
                        <p class="mb-0"><strong>$ 63</strong> /year</p>
                    </div>
                    <div class="text-center">
                        <v-btn class="ma-2 subtitle-1" depressed color="light-blue accent-4" dark large :to="{name: 'SignUp', params: {type: 'full'}}">
                            Upgrate to pro 
                        </v-btn>
                    </div>
                    <v-list class="ml-sm-4 ml-md-8 ml-lg-12 ml-xl-16 ">
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Teaching Tools  <v-icon color="primary" @click="dialogTools = true">mdi-help-circle</v-icon></v-list-item-title>
                                <v-list-item-subtitle class="text-subtitle-1">Sonidos</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Sílabas</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Juntando 2 Sílabas</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Sílabas Inversas y Extensiones</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Juntando 3 sílabas</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Sílabas trabadas y especiales</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Resources</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <!-- <v-icon color="green">mdi-check</v-icon> -->
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Classrooms</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <!-- <v-icon color="green">mdi-check</v-icon> -->
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Reports</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                   
                </v-card>
            </v-col>
            <v-col sm="6" md="4" lg="4">
                <v-card elevation="2">
                    <div class="title-option text-center">
                        Schools
                    </div>
                    <div class="price-option text-center">
                        <p class="pt-5">Quote for year<strong></strong></p>
                    </div>
                    <div class="text-center">
                        <v-btn class="ma-2 subtitle-1" depressed color="light-blue accent-4" dark large to="/request-a-quote">
                            Contact Us for a Quote 
                        </v-btn>
                    </div>
                    <v-list class="ml-sm-4 ml-md-8 ml-lg-12 ml-xl-16">
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Teaching Tools  <v-icon color="primary" @click="dialogTools = true">mdi-help-circle</v-icon></v-list-item-title>
                                <v-list-item-subtitle class="text-subtitle-1">Sonidos</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Sílabas</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Juntando 2 Sílabas</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Sílabas Inversas y Extensiones</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Juntando 3 sílabas</v-list-item-subtitle>
                                <v-list-item-subtitle class="text-subtitle-1">Sílabas trabadas y especiales</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Resources</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Student Edition <v-icon color="primary" @click="dialogStudent = true">mdi-help-circle</v-icon></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6">Classrooms</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon class="mr-4">
                                <v-icon color="green">mdi-check</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-h6"><v-icon color="green"></v-icon> Reports</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>                        
                    </v-list>
                    
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogTools" width="700">
            <v-card>
                <!-- <v-card-title class="text-h5 cyan accent-4">
                </v-card-title> -->
                <p class="text-h5 py-2 cyan accent-4 text-center mb-0">Teaching Tools</p>
                <v-carousel v-model="model">
                    <v-carousel-item src="../assets/homepage/mision1.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Aprendiendo los sonidos</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/mision2.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Las sílabas</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/mision3.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Juntando 2 sílabas</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/mision4.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Sílabas Inversas y Extensiones</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/mision5.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Juntando 3 sílabas</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/mision6.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Sílabas trabadas y especiales</div>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
               
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogStudent" width="900">
            <v-card>
                <!-- <v-card-title class="text-h5 cyan accent-4">
                </v-card-title> -->
                <p class="text-h5 py-2 cyan accent-4 text-center mb-0">Student Edition</p>
                <v-carousel v-model="model">
                    <v-carousel-item src="../assets/homepage/student1.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>IOS App</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/student2.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Misiones, Niveles</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/student3.png">
                        <!-- <v-row class="fill-height fondo" justify="center">
                            <div>Estrategias</div>
                        </v-row> -->
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/student4.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Animaciones y dinámicas de enseñanza</div>
                        </v-row>
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/student5.png">
                        <!-- <v-row class="fill-height fondo" justify="center">
                            <div>Armar Sílabas, palabras</div>
                        </v-row> -->
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/student6.png">
                        <!-- <v-row class="fill-height fondo" justify="center">
                            <div>Selección en base al sonido</div>
                        </v-row> -->
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/student7.png">
                        <!-- <v-row class="fill-height fondo" justify="center">
                            <div>Sílabas trabadas y especiales</div>
                        </v-row> -->
                    </v-carousel-item>
                    <v-carousel-item src="../assets/homepage/student8.png">
                        <v-row class="fill-height fondo" justify="center">
                            <div>Prueba Final en base al progreso del estudiante</div>
                        </v-row>
                    </v-carousel-item>
                </v-carousel>
               
            </v-card>
        </v-dialog>
        <p class="text-caption">* For the Teaching Tools we suggest using an Windows or Mac.</p>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            dialogTools:false,
            dialogStudent: false,
            model: 0,
            colors: [
                'primary',
                'secondary',
                'yellow darken-2',
                'red',
                'orange',
            ],
            // sliders: [
            //     { name: 'Aprendiendo los sonidos', src: "../assets/homepage/mision1.png"},
            //     { name: 'Las sílabas', src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
            //     { name: 'Juntando 2 sílabas', src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
            //     { name: 'Sílabas Inversas y Extensiones', src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
            //     { name: 'Juntando 3 sílabas', src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
            //     { name: 'Sílabas trabadas y especiales', src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg'},
            // ]
        }
    },
}
</script>
<style lang="scss" scoped>

    .title-option{
        padding-top: 1.1em;
        font-size: 1.7em;
        font-weight: 800;
        color: #01a2a5;
    }
    .price-option{
        background-color: #00B8D4;
        color: white;
        min-height: 50px;
        font-weight: 700;
        font-size: 1.5em;
        padding: 5px;
    }
    .price-option strong{
        font-size: 2.5em;
    }
    .price-option {
  del {
    color: rgba(red, 0.5);
    text-decoration: none;
    position: relative;
    &:before {
      content: " ";
      display: block;
      width: 100%;
      border-top: 2px solid rgba(red, 0.8);
      height: 12px;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: rotate(-7deg);
    }
  }
  ins {
    color: green;
    font-size: 32px;
    text-decoration: none;
    padding: 1em 1em 1em 0.5em;
  }
}
.fondo{
    background-color: #6e6e6ef2;
    height: 50px;
    padding-top: 10px;
    font-size: 25px;
}
</style>